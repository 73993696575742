import { APP_INITIALIZER, Provider } from '@angular/core';
import { initializeMapScript } from '@qaroni-core/utils/scripts/scripts-map';

export const providersInitialize = (): Provider[] => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMapScript,
      multi: true,
    },
  ];
};
