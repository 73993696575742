import { GoogleMapsEnv } from '@qaroni-core/config/apis/google-maps/google-maps.config';

const loadMapScript = (mapKey: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=geometry,maps,marker&loading=async`;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Error loading script'));
    document.head.appendChild(script);
  });
};

export function initializeMapScript(): () => Promise<void> {
  return () => loadMapScript(GoogleMapsEnv.key);
}
